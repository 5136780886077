/*===============================
# Data Displayer Item
=================================*/
.data-displayer-single-item {
    position: relative;
    background-color: white;
    border: 1px solid #00000017;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
}
.content-of-data-displayer-single-item::-webkit-scrollbar {
    width: 2px !important;
    height: 3px;
}
.content-of-data-displayer-single-item {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 35px;
    box-sizing: border-box;
    overflow-x: scroll;
}
.content-of-single-data-displayer-item-fields-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.content-of-buttons-container-of-data-displayer-single-item {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.single-field-of-data-displayer-item {
    padding-right: 15px;
}
.single-field-of-data-displayer-item h3{
    margin: 3px 0 0;
}
.single-field-of-data-displayer-item h4{
    margin: 0;
    font-weight: 400;
    font-size: 15px;
}
.single-data-displayer-item-fields-container .MuiAvatar-root {
    width: 50px;
    height: 50px;
    background-color: #bdbdbd;
}
.single-data-displayer-item-buttom {
    margin-left: 5px;
}
.content-of-data-displayer-single-item-sub-items-container {
    position: relative;
    padding-left: 35px;
}
.single-field-of-data-displayer-item a {
    color: #f05f2a;
    text-decoration: none;
    transition: all 0.35s ease-in-out;
}
.single-field-of-data-displayer-item a:hover,
.single-field-of-data-displayer-item a:focus{
    opacity: .5;
}
/*===============================
# Pagination
=================================*/
.data-displayer-pagination-container{
    padding: 15px 0 0;
}
/*===============================
# Search
=================================*/
.data-displayer-search-container{
    margin-bottom: 15px;
}
.search-textfield-box-of-data-displayer-search-container .MuiOutlinedInput-notchedOutline{
    border-color: #00000017;
    transition: all 0.25s ease-in-out;
}
.params-form-of-data-displayer-search-container {
    padding: 10px 0 0;
}
.params-form-of-data-displayer-search-container .MuiOutlinedInput-root{
    border-color: #00000017;
    transition: all 0.25s ease-in-out;
}
.params-form-of-data-displayer-search-container .MuiButton-root {
    height: 55px;
}
/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/

/*===============================
#
=================================*/