/*===============================
# Home Head box
=================================*/
.logo-of-home-headbox-container img{
  width: 120px;
}
.logo-of-home-headbox-container{
  margin-bottom: 20px;
}
.home-headbox-container h1 {
  margin: 0 0 0;
}
.search-home-headbox-container {
  padding-top: 35px;
}
/*===============================
# Sections
=================================*/
.sections-list-container{
  padding-top: 50px;
}
.content-of-sections-list-container {
  flex-wrap: wrap;
  gap: 15px;
  display: flex;
}
.wrapper-of-single-section-box {
  position: relative;
  width: 49%;
}
.single-section-box {
  position: relative;
  width: 100%;
  /* height: 500px; */
  border-radius: 10px;
  overflow: hidden;
  aspect-ratio: 1/1;
}
.content-of-single-section-box {
  position: relative;
  width: 100%;
  height: 100%;
}
.mask-text-of-single-section-box {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgb(0 0 0 / 70%) 20%, rgba(0, 0, 0, 0) 100%);
  box-sizing: border-box;
}
.thumbnail-box-of-single-section-box {
  position: relative;
  width: 100%;
  height: 100%;
}
.thumb-of-single-section-box {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
.content-of-mask-text-of-single-section-box {
  position: relative;
  display: flex;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}
.mask-text-of-single-section-box h3{
  color: white;
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  /* padding: 35px; */
}
.content-of-title-box-of-single-section-box {
  position: relative;
  display: flex;
  align-items: center;
  padding: 35px;
}
.icon-of-title-box-of-single-section-box {
  color: white;
  margin-right: 10px;
  margin-top: 6px;
  transition: all 0.45s ease-in-out;
  margin-left: -20px;
  opacity: 0;
}
.single-section-box:hover .icon-of-title-box-of-single-section-box,
.single-section-box:focus .icon-of-title-box-of-single-section-box{
  margin-left: 0;
  opacity: 1;
}
/*===============================
# Single section page
=================================*/
.single-section-details-box h1{
  margin: 0;
}
.content-of-title-of-single-section-details-box {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}
.wrapper-of-single-category-box a {
  text-decoration: none;
}
.wrapper-of-single-category-box {
  padding-bottom: 10px;
}
.section-categories-list-container {
  padding-top: 35px;
}
.single-category-box {
  position: relative;
  background-color: #f0f0f0;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  color: #2e2e2e;
  transition: all 0.45s ease-in-out;
}
.single-category-box:hover .icon-box-of-single-category-box {
  margin-left: 15px;
}
.single-category-box:hover, .single-category-box:focus{
  background-color: #e5e5e5;
}
.content-of-single-category-box {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px 35px;
  display: flex;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
}
.icon-box-of-single-category-box {
  width: 5%;
  transition: all 0.45s ease-in-out;
}
.title-box-of-single-category-box {
  width: 85%;
  transition: all 0.45s ease-in-out;
}
.right-icon-box-of-single-category-box {
  width: 15%;
  transition: all 0.45s ease-in-out;
}
.content-of-right-icon-box-of-single-category-box {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.title-box-of-single-category-box h3{
  font-weight: 500;
  cursor: pointer;
  transition: all 0.45s ease-in-out;
}
.breadcrumbs-of-single-section-details-box {
  padding-top: 15px;
}
.detailed-post-items-container {
  position: relative;
  width: 100%;
}
.single-post-detail-box h3{
  margin: 0;
  font-weight: 600;
  font-size: 15px;
}
.single-post-detail-box h4{
  margin: 0 0 0;
  font-weight: 400;
}
.content-of-detailed-post-items-container {
  position: relative;
  display: flex;
  gap: 35px;
}
.single-post-detail-box{
  padding: 10px 0;
}
.single-category-box:hover .right-icon-box-of-single-category-box{
  margin-left: -10px;
}
.content-of-post-additional-info-boxes-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
}
.content-of-additional-post-info-title-box {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}
.text-of-additional-post-info-title-box p{
  margin: 0;
}
.icon-of-additional-post-info-title-box {
  margin: 5px 0 0;
}
.post-additional-info-boxes-container {
  padding-top: 10px;
}
.additional-post-info-title-box {
  color: #353535;
  cursor: pointer;
}
/*===============================
# Printed Post
=================================*/
.printed-post-container {
  /* position: fixed; */
  background-color: white;
  /* min-width: 2480px; */
  /* width: 100%; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* z-index: -9999999; */
  /* overflow-y: scroll;
  overflow-x: scroll; */
  /* margin-top: -10000000000000px; */
}
.logo-of-header-of-printed-post-container {
  width: 120px;
  height: 55px;
}
.content-of-logo-of-header-of-printed-post-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.logo-of-header-of-printed-post-container svg {
  width: 100%;
  height: 100%;
}
.title-box-of-header-of-printed-post-container h2{
  margin: 10px 0 0;
}
.content-of-header-of-printed-post-container {
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  padding: 0 50px;
  gap: 35px;
}
.header-of-printed-post-container {
  border-bottom: 1px solid #0000001a;
  padding: 25px 0;
}
.content-of-post-body-of-printed-post-container {
  position: relative;
  padding: 50px;
}
.header-of-post-body-of-printed-post-container h1{
  margin: 0;
}
.header-of-post-body-of-printed-post-container p {
  margin: 5px 0 0;
  color: #0000009c;
}
.header-of-post-body-of-printed-post-container{
  padding-bottom: 35px;
}
.file-block-box p{
  margin: 0 0 0 10px;
}
.file-block-box {
  background-color: #f0f0f0;
  width: fit-content;
  border-radius: 10px;
  color: #393939;
  min-width: 350px;
  max-width: 500px;
  transition: all 0.45s ease-in-out;
}
.file-block-box a,
.file-block-box p,
.file-block-box svg{
  color: #393939;
}
.file-block-box a {
  text-decoration: none;
}
.file-block-box:hover, .file-block-box:focus{
  opacity: .7;
  cursor: pointer;
}
.content-of-file-block-box {
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px;
}
.icon-of-file-block-box {
  margin-top: 4px;
}
.block-editor-form-container a,
.rendered-html-content a{
  text-decoration: none;
  color: #f05f2a;
  font-weight: 500;
  transition: all 0.35s ease-in-out;
}
.rendered-html-content a:hover, .rendered-html-content a:focus{
  opacity: .5;
}
.details-box-of-file-block-box {
  position: relative;
}
.content-of-details-box-of-file-block-box {
  display: flex;
  align-items: center;
  padding: 15px;
}
.file-block-box.with-preview .content-of-file-block-box {
  display: block;
  padding: 0;
}
.thumbnail-box-of-file-block-box {
  position: relative;
  width: 100%;
  height: 250px;
  padding: 5px;
  box-sizing: border-box;
}
.content-of-thumbnail-box-of-file-block-box{
  position: relative;
  width: 100%;
  height: 100%;
}
.preview-of-file-block-box {
  width: 100%;
  height: 100%;
  background-size: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}
.video-iframe-block{
  padding: 56.25% 0 0 0;
  position: relative;
}
.video-iframe-block iframe{
  width: 100%;
  height: 100%;
  border: none;
  position:absolute;
  top:0;
  left:0;
}
.in-block-iframe{
  width: 450px;
  height: 250px;
}
/*===============================
# Breadcrumbs
=================================*/
.content-of-breadcrumbs-container {
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
}
.text-box-of-intro-section-home-page .breadcrumbs-container {
  padding-top: 15px;
}
.breadcrumbs-container a{
  text-decoration: none;
  color: black;
  transition: all 0.35s ease-in-out;
}
.breadcrumbs-container a.no-link{
  color: #616161;
}
.breadcrumbs-container a:hover, .breadcrumbs-container a:focus{
  color: #f05f2a;
}
.breadcrumbs-container a.no-link:hover, .breadcrumbs-container a.no-link:focus{
  color: black;
}
.breadcrumb-separator-icon svg {
  width: 15px;
  height: 15px;
  margin: 6px 0 0;
}
.breadcrumb-separator-icon {
  padding: 0 5px;
}
.content-of-single-breadcrumb-item-box {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  gap: 5px;
}
/*===============================
# Post Page
=================================*/
.post-content-container {
  padding-top: 35px;
}
.single-post-page-content {
  display: flex;
}
.post-sidebar-section-of-the-post{
  width: 20%;
}
.post-html-content-section {
  width: 80%;
}
.content-of-post-html-content-section {
  padding-left: 30px;
}
.header-of-post-sidebar-section h3{
  margin: 0;
}
.title-box-of-sidebar-single-post-category h4{
  margin: 0;
  font-weight: 500;
  transition: all 0.45s ease-in-out;
}
.single-sidebar-category-box h4{
  font-weight: 400;
  font-size: 15px;
  margin: 0;
  transition: all 0.45s ease-in-out;
}
.title-box-of-sidebar-single-post-category h4:hover,
.title-box-of-sidebar-single-post-category h4:focus,
.single-sidebar-category-box h4:hover, .single-sidebar-category-box h4:focus{
  opacity: .5;
  cursor: pointer;
}
.sidebar-single-post-category {
  margin-bottom: 10px;
}
.items-of-post-sidebar-section {
  padding-top: 20px;
}
.content-of-posts-box-of-sidebar-single-post-category {
  padding-left: 15px;
}
.posts-box-of-sidebar-single-post-category {
  border-left: 1px solid #e1e1e1;
  margin-top: 10px;
  display: none;
}
.sidebar-single-post-category.active .posts-box-of-sidebar-single-post-category{
  display: block;
}
.single-sidebar-category-box {
  padding: 5px 0;
}
.single-sidebar-category-box.active h4,
.sidebar-single-post-category.active .title-box-of-sidebar-single-post-category h4{
  color: #f05f2a;
}
.single-sidebar-category-box a {
  text-decoration: none;
  color: #505050;
}
.mobile-post-sidebar-section-of-the-post {
  position: relative;
  width: 100%;
  height: 90%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 99999;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
  margin-bottom: -1000px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.45s ease-in-out;
}
.mask-of-mobile-post-sidebar-section-of-the-post {
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.311);
  opacity: 0;
  visibility: hidden;
  transition: all 0.45s ease-in-out;
}
.content-of-mobile-post-sidebar-section-of-the-post {
  position: relative;
  box-sizing: border-box;
  padding: 35px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.close-icon-box-of-mobile-post-sidebar-section-of-the-post {
  position: absolute;
  right: 35px;
}
.mobile-post-sidebar-section-of-the-post-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.45s ease-in-out;
}
.content-of-wrapper-of-mobile-post-sidebar-section-of-the-post{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.wrapper-of-mobile-post-sidebar-section-of-the-post{
  position: relative;
  width: 100%;
  height: 100%;
}
/* .wrapper-of-mobile-post-sidebar-section-of-the-post {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
} */
.mobile-post-sidebar-section-of-the-post-container.active .mask-of-mobile-post-sidebar-section-of-the-post{
  opacity: 1;
  visibility: visible;
}
.mobile-post-sidebar-section-of-the-post-container.active .mobile-post-sidebar-section-of-the-post{
  opacity: 1;
  visibility: visible;
  margin-bottom: 0;
}
.mobile-post-sidebar-section-of-the-post-container.active{
  opacity: 1;
  visibility: visible;
}
.content-of-show-mobile-page-post-content-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px;
}
.show-mobile-page-post-content-container p {
  margin: 0;
  font-weight: 500;
}
.show-mobile-page-post-content-container {
  position: relative;
  background-color: #00000017;
  display: block;
  border-radius: 15px;
  margin-bottom: 35px;
  transition: all 0.45s ease-in-out;
}
.show-mobile-page-post-content-container:hover, .show-mobile-page-post-content-container:focus {
  opacity: .7;
  cursor: pointer;
}
.show-mobile-page-post-content-container{
  display: none;
}
/*===============================
# Rendred blocks content
=================================*/
.rendered-html-content figure {
  margin: 0;
}
.rendered-html-content img{
  max-width: 100%;
}
.rendered-html-content p {
  line-height: 1.6;
}
.rendered-html-content td:first-child,
.bn-container td:first-child {
  background-color: #ececec !important;
  font-weight: 500 !important;
}
.rendered-html-content td,
.bn-container td{
  padding: 0 15px !important;
  border: 1px solid #0000000d !important;
}
.rendered-html-content table,
.bn-container table{
  border-spacing: 0 !important;
}
.children-items-block-edtor li {
  list-style: none;
}
.children-items-block-edtor {
  border-left: 1px solid #00000017;
  padding: 0 0 0 20px;
}
/*===============================
# Load more button
=================================*/
.load-more-button-container{
  position: relative;
  background-color: #f0f0f0;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  color: #2e2e2e;
  transition: all 0.45s ease-in-out;
}
.load-more-button-container:hover, .load-more-button-container:focus{
  opacity: .5;
  cursor: pointer;
}
.content-of-load-more-button-container{
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px 35px;
  display: flex;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
}
.load-icon-of-load-more-button-container {
  width: 4%;
}
.text-of-load-more-button-container h3{
  font-weight: 500;
}
.next-post-button-container a {
  text-decoration: none;
}
.next-post-button-container {
  padding-top: 35px;
}
.mobile-post-bottom-buttons{
  display: none;
}
.mobile-post-bottom-buttons .next-post-button-container{
  padding-top: 10px;
}
/*===============================
# Search box header
=================================*/
.miraj-header-search-bar-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.content-of-miraj-header-search-bar-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}
.search-bar-header-form-elem-box {
  position: relative;
  width: 100%;
}
/*===============================
# User profile header box
=================================*/
.user-avatar-menu-box {
  transition: all 0.45s ease-in-out;
}
.user-avatar-menu-box:hover, .user-avatar-menu-box:focus{
  opacity: .7;
  cursor: pointer;
}
.user-avatar-pop-up-box {
  position: absolute;
  width: 200px;
  background: white;
  margin-left: -70px;
  margin-top: 80px;
  border-radius: 15px;
  box-shadow: 0px 13px 30px #00000036;
  opacity: 0;
  visibility: hidden;
  transition: all 0.45s ease-in-out;
}
.user-profile-avatar-box-miraj-header-container:hover .user-avatar-pop-up-box{
  margin-top: 20px;
  opacity: 1;
  visibility: visible;
}
.user-avatar-pop-up-box a {
  text-decoration: none;
}
.single-user-avatar-profile-menu-item h3{
  margin: 0;
  color: black;
  font-weight: 400;
  text-align: center;
  font-size: 15px;
}
.content-of-user-avatar-pop-up-box{
  position: relative;
  padding: 15px 0;
}
.single-user-avatar-profile-menu-item {
  position: relative;
  width: 100%;
  padding: 10px 0;
  transition: all 0.45s ease-in-out;
}
.single-user-avatar-profile-menu-item:hover, .single-user-avatar-profile-menu-item:focus{
  background-color: #00000012;
  opacity: .8;
  cursor: pointer;
}
.single-user-avatar-profile-menu-item.red-link h3{
  color: red;
}
.single-user-avatar-profile-menu-item.red-link:hover {
  background-color: #ff00000d;
}
/*===============================
# Password Checker
=================================*/
.verify-strong-password-container{
  padding-top: 10px;
}
.item-of-verify-strong-password-container.checked {
  background-color: #add89933;
  color: #588444;
}
.item-of-verify-strong-password-container{
  border-radius: 5px;
  margin-bottom: 5px;
  background-color: #ff00000f;
  color: #FF0000;
}
.content-of-item-of-verify-strong-password-container {
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  padding: 10px;
}
.text-of-item-of-verify-strong-password-container p {
  margin: 0;
}
.text-of-item-of-verify-strong-password-container {
  padding-left: 10px;
}
/*===============================
# Security Section
=================================*/
.buttons-of-items-of-single-security-section-box button, .buttons-of-items-of-single-security-section-box a{
  margin-right: 5px;
}
.single-security-section-box {
  border: 1px solid #0000001f;
  border-radius: 10px;
  margin-bottom: 15px;
}
.content-of-single-security-section-box {
  position: relative;
  box-sizing: border-box;
  padding: 35px;
}
.content-of-title-of-title-box-of-single-security-section-box {
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
}
.title-of-title-box-of-single-security-section-box h3{
  margin: 0;
}
.text-of-title-of-title-box-of-single-security-section-box p{
  margin: 5px 0 0;
}
.text-of-title-of-title-box-of-single-security-section-box{
  padding-left: 15px;
}
.items-of-single-security-section-box{
  padding-top: 30px;
}
.icon-of-title-of-title-box-of-single-security-section-box {
  background-color: #f05f2a52;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  color: #f05f2a;
}
.content-of-icon-of-title-of-title-box-of-single-security-section-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
/*===============================
# Update Password
=================================*/
.login-form-container.update-password-form{
  max-width: 500px;
}
/*===============================
# Dark mode
=================================*/
body.dark-mode {
  background-color: #0F0F0F;
}
body.dark-mode .miraj-warehouse-header{
  background: rgb(0 0 0 / 82%);
}
body.dark-mode .user-avatar-pop-up-box{
  background: #1f1f1f;
}
body.dark-mode .single-user-avatar-profile-menu-item h3{
  color: white;
}
body.dark-mode .single-user-avatar-profile-menu-item.red-link h3{
  color: red;
}
body.dark-mode  .home-headbox-container h1{
  color: white;
}
body.dark-mode .single-warehouse-menu-item a{
  color: white;
}
body.dark-mode  .miraj-warehouse-footer{
  background-color: #0F0F0F;
  border-top: 1px solid #ffffff3b;
}
body.dark-mode .mobile-sidebar-container{
  background-color: #0F0F0F;
}
body.dark-mode .copyright-text-footer{
  color: white;
}
body.dark-mode .single-section-details-box h1{
  color: white;
}
body.dark-mode .breadcrumbs-container a{
  color: #b7b7b7;
}
body.dark-mode .breadcrumb-separator-icon svg{
  color: #b7b7b7;
}
body.dark-mode .breadcrumbs-container a:hover, body.dark-mode .breadcrumbs-container a:focus{
  color: #f05f2a;
}
body.dark-mode .breadcrumbs-container a.no-link:hover, .breadcrumbs-container a.no-link:focus{
  color: #b7b7b7;
}
body.dark-mode .single-category-box{
  background: transparent;
  border: 1px solid #ffffff3b;
  color: white;
}
body.dark-mode .posts-box-of-sidebar-single-post-category {
  border-left: 1px solid #e1e1e130;
}
body.dark-mode .header-of-post-sidebar-section h3{
  color: white;
}
body.dark-mode .single-post-page-post-blocks-content .rendered-html-content {
  color: white;
}
body.dark-mode .single-post-page-post-blocks-content .rendered-html-content td:first-child, body.dark-mode .single-post-page-post-blocks-content .bn-container td:first-child{
  background-color: #ffffff05 !important;
}
body.dark-mode .single-post-page-post-blocks-content .rendered-html-content td, body.dark-mode .single-post-page-post-blocks-content .bn-container td{
  border: 1px solid #ffffff14 !important;
}
body.dark-mode .single-sidebar-category-box a{
  color: rgb(206, 206, 206);
}
body.dark-mode .additional-post-info-title-box{
  color: rgb(206, 206, 206);
}
body.dark-mode  .user-info-box-sidebar {
  background-color: transparent;
  border: 1px solid #ffffff3b;
  color: white;
}
body.dark-mode  .user-info-box-sidebar a {
  color: white;
}
body.dark-mode .head-box-of-profile-main-content-container h2{
  color: white;
}
body.dark-mode .text-box-of-form-element-image-uploader-container {
  color: white;
}
body.dark-mode .form-element-image-uploader-container{
  border: 1px solid #ffffff3b;
}
body.dark-mode .data-displayer-single-item{
  background-color: transparent;
  border: 1px solid #ffffff3b;
}
body.dark-mode .two-factor-auth-code-field-container{
  background-color: transparent;
  border: 1px solid #ffffff3b;
  color: white;
}
body.dark-mode .admin-panel-sidebar{
  background-color: #0F0F0F;
  border-right: 1px solid #ffffff3b;
}
body.dark-mode  .hide-sidebar-box svg{
  color: rgb(168, 168, 168);
}
body.dark-mode .single-admin-panel-menu-item a{
  color: #cccccc;
}
body.dark-mode .sop-page-section-container{
  background-color: #0F0F0F;
}
body.dark-mode .admin-panel-head-section h1{
  color: white;
}
body.dark-mode .load-more-button-container{
  color: white;
  border: 1px solid #ffffff3b;
  background-color: transparent;
}
body.dark-mode .single-post-detail-item-box{
  border-bottom: 1px solid rgb(217 217 217 / 21%);
}
body.dark-mode  .post-editor-sop-modal{
  background-color: #0F0F0F;
}
body.dark-mode .text-of-head-box-of-post-modal-sop-container-content h3{
  color: white;
}
body.dark-mode .post-form-block-editor-container{
  margin-top: 0;
}
body.dark-mode .single-field-of-data-displayer-item{
  color: white;
}
body.dark-mode .single-field-of-data-displayer-item h4{
  opacity: .6;
}
body.dark-mode .single-data-displayer-item-fields-container .MuiAvatar-root{
  background-color: #ffffff14;
  color: white;
}
/* body.dark-mode .search-textfield-box-of-data-displayer-search-container .MuiOutlinedInput-notchedOutline {
  border: 1px solid #ffffff3b;
}
body.dark-mode .search-textfield-box-of-data-displayer-search-container .MuiOutlinedInput-notchedOutline:focus {
  border: 1px solid red;
} */
body.dark-mode .admin-panel-single-section-container{
  border: 1px solid #ffffff3b;
}
body.dark-mode .admin-header-container{
  background: transparent;
  border-bottom: 1px solid #ffffff3b;
}
body.dark-mode .login-form-container{
  border: 1px solid #ffffff3b;
}
body.dark-mode .text-box-of-two-factor-auth-head-box{
  color: white;
}
body.dark-mode .text-box-of-two-factor-auth-head-box p {
  opacity: .6;
}
body.dark-mode .title-box-of-login-form-container h3{
  color: white;
}
body.dark-mode .page-preloader-container {
  background: #0F0F0F;
}
.content-of-change-mode-container-for-user-avatar-pop-up-box{
  display: flex;
  justify-content: center;
}
body.dark-mode .title-box-of-sidebar-single-post-category h4{
  color: white;
}
body.dark-mode .show-mobile-page-post-content-container{
  background-color: transparent;
  color: white;
  border: 1px solid #ffffff3b;
}
body.dark-mode .mobile-post-sidebar-section-of-the-post{
  background-color: #0F0F0F;
}
body.dark-mode .single-post-page-post-blocks-content .file-block-box{
  background-color: rgba(255, 255, 255, 0.024);
  border: 1px solid #ffffff3b;
  color: white;
}
body.dark-mode .single-post-page-post-blocks-content .file-block-box p, .single-post-page-post-blocks-content body.dark-mode .file-block-box svg{
  color: #c7c7c7;
}
body.dark-mode .title-of-title-box-of-single-security-section-box h3{
  color: white;
}
body.dark-mode .title-of-title-box-of-single-security-section-box p{
  color: white;
  opacity: .5;
}
body.dark-mode .single-security-section-box{
  border: 1px solid #ffffff3b;
}
body.dark-mode p.two-factor-auth-description {
  color: white;
  opacity: .8;
}
body.dark-mode .two-factor-auth-qr-code-container{
  border: 1px solid #ffffff3b;
}
/*===============================
# 
=================================*/
