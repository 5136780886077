/*===============================
# Fonts
=================================*/
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600;700;800&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Prompt:wght@100;300;400;500;700&display=swap'); */

/*===============================
# Scrollbar
=================================*/
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f05f2a;
  transition: all 0.35s ease-in-out;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f05f2a;
}
/*===============================
# Base
=================================*/
body{
  font-family: "Poppins";
  /* font-family: "Prompt" !important; */
  margin: 0;
  width: 100%;
  height: 100%;
}
.content-section-wrapper{
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}
/*===============================
# Login Page
=================================*/
.login-form-container {
  max-width: 400px;
  border: 1px solid #00000017;
  border-radius: 5px;
  box-shadow: 0px 5px 50px #00000000;
  margin: 50px auto;
}
.login-form-container.reset-password-form{
  max-width: 500px;
}
.content-of-login-form-container{
  padding: 35px;
}
.sign-in-page-container{
  position: relative;
  width: 100%;
  /* height: 100vh; */
  padding-bottom: 100px;
}
.content-of-sign-in-page-container{
  position: relative;
  width: 100%;
  height: 100%;
  /* display: flex; */
  justify-content: center;
  align-items: center;
}
.app-logo-of-login-form-container img{
  max-width: 200px;
}
.content-of-app-logo-of-login-form-container{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.actual-form-of-login-form-container{
  padding-top: 35px;
}
/*===============================
# Page Preloader
=================================*/
.page-preloader-container.active{
    visibility: visible;
    opacity: 1;
}
.page-preloader-container{
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: white;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.45s ease-in-out;
}
.content-of-page-preloader-container{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*===============================
# Error Page
=================================*/
.error-page-container{
  position: relative;
  width: 100%;
  height: 100vh;
}
.content-of-error-page-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.icon-box-of-error-page-container svg{
  font-size: 45px;
  color: red;
}
.text-box-of-error-page-container{
  padding-top: 10px;
}
.text-box-of-error-page-container h1{
  font-size: 35px;
  text-align: center;
  color: red;
  margin: 0;
}
.text-box-of-error-page-container p {
  margin: 10px 0 0;
}
.buttons-of-error-page-container{
  padding-top: 35px;
}
.buttons-of-error-page-container .MuiButton-root{
  margin-right: 5px;
}
/*===============================
# Bottom Button FIxed
=================================*/
.add-new-item-bottom-container a {
  text-decoration: none;
  margin-right: 5px;
}
.add-new-item-bottom-container {
  position: fixed;
  bottom: 35px;
  right: 35px;
}
/*===============================
# Item Details
=================================*/
.title-box-of-admin-panel-single-section-container h3{
  margin: 0;
  font-size: 24px;
}
.title-box-of-admin-panel-single-section-container{
  padding-bottom: 30px;
}
.thumb-box-of-single-item-details-page-boxes-container{
  position: relative;
  width: 100%;
}
.thumb-box-of-single-item-details-page-boxes-container img {
  width: 100%;
}
.img6-5cm-container-of-stickers-page-item {
  max-width: 6.5cm;
}
.content-of-img6-5cm-container-of-stickers-page-item {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.img6-5cm-container-of-stickers-page-item{
  margin-top: -1px;
}
/*===============================
# User groups
=================================*/
.warehouse-access-container {
  border: 1px solid #00000017;
  border-radius: 5px;
  position: relative;
}
.content-of-warehouse-access-container {
  position: relative;
  padding: 35px;
}
.title-box-of-warehouse-access-container h3{ 
  margin: 0;
}
.single-accessed-warehouse-box {
  border: 1px solid #00000017;
  border-radius: 5px;
  position: relative;
  margin-bottom: 10px;
}
.title-box-of-single-accessed-warehouse-box{
  margin: 0;
  border-bottom: 1px solid #00000017;
  height: 50px;
}
.title-box-of-single-accessed-warehouse-box h3{
  line-height: 50px;
  margin: 0;
  padding-left: 10px;
}
.all-the-warehouses-accessed-container {
  padding-top: 20px;
}
.single-section-of-accessed-warehouse-box {
  margin: 0;
  border-bottom: 1px solid #00000017;
  height: 50px;
}
.single-section-of-accessed-warehouse-box:last-child{
  border-bottom: 0;
}
.content-of-single-section-of-accessed-warehouse-box {
  position: relative;
  padding-left: 10px;
}
.single-section-of-accessed-warehouse-box .MuiFormControlLabel-root{
  margin-top: 3px;
}
/*===============================
# Set password form
=================================*/
.title-box-of-login-form-container h3{
  margin: 0;
}
.title-box-of-login-form-container{
  padding-top: 20px;
}
/*===============================
# QR Code Scanner
=================================*/
.qr-code-scanner-page-container{
  position: relative;
  width: 100%;
  height: 100%;
}
/*===============================
# Where is item
=================================*/
.where-is-item-single-box {
  border: 1px solid #00000017;
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 5px;
}
.message-box-of-admin-where-is-item-box-container {
  margin-bottom: 15px;
}
.where-is-item-single-box h4{
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.where-is-item-single-box h3{
  font-weight: bold;
  margin: 5px 0 0;
}
.content-of-where-is-item-single-box {
  padding: 35px;
}
/*===============================
# Profile Page
=================================*/
.user-profile-page-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  /* max-width: 75%; */
  /* padding-top: 50px; */
}
.profile-menu-container-wrapper {
  padding-top: 20px;
}
.profile-menu-container-wrapper .admin-panel-menu-navigation {
  padding: 0;
}
.head-box-of-profile-main-content-container h2{
  margin: 0;
}
.head-box-of-profile-main-content-container{
  margin-bottom: 35px;
}
/* .profile-page-content-component-container{
  padding-top: 35px;
} */
.content-of-of-profile-main-content-container{
  padding-left: 15px;
}
.profile-item-transfer-requests-section-container {
  padding-bottom: 50px;
}
.back-to-profile-section-page-box button {
  margin: -2px 5px 0 0;
}
.back-to-profile-section-page-box {
  padding-bottom: 20px;
}
.back-to-profile-section-page-box h3 {
  margin: 0;
}
.back-to-profile-section-page-box a {
  color: black;
  text-decoration: none;
}
/*===============================
# Home
=================================*/
.logo-box-of-miraj-warehouse-header img{
  max-width: 120px;
}
.miraj-warehouse-header {
  position: fixed;
  width: 100%;
  /* background-color: white; */
  /* border-bottom: 1px solid #80808038; */
  height: 125px;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  background: rgb(255 255 255 / 90%);
  /* box-shadow: 0 4px 30px rgb(0 0 0 / 9%); */
  backdrop-filter: blur(9.6px);
  -webkit-backdrop-filter: blur(9.6px);
  border: 1px solid rgba(255, 255, 255, 0.21);
  border-top: none;
  border-left: none;
  border-right: none;
}
.content-of-miraj-warehouse-header {
  position: relative;
  width: 100%;
  /* max-width: 95%; */
  /* max-width: 1170px; */
  height: 100%;
  margin: 10px auto;
}
.miraj-warehouse-header .MuiGrid-container {
  position: relative;
  height: 100%;
}
.logo-box-of-miraj-warehouse-header {
  position: relative;
  height: 100%;
  width: 100%;
}
.content-of-logo-box-of-miraj-warehouse-header {
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.miraj-warehouse-menu-header {
  position: relative;
  width: 100%;
  /* height: 100%; */
}
.mobile-header-search-box-box {
  padding-top: 15px;
}
.content-of-miraj-warehouse-menu-header {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.single-warehouse-menu-item a {
  text-decoration: none;
  color: black;
  font-weight: 500;
  transition: all 0.35s ease-in-out;
}
.single-warehouse-menu-item a:hover,
.single-warehouse-menu-item a:focus{
  color: #f05f2a;
}
.single-warehouse-menu-item {
  padding-right: 30px;
}
.miraj-warehouse-footer {
  background-color: #ececec;
}
.main-page-content-container{
  padding-top: 200px;
  padding-bottom: 200px;
}
.content-of-main-page-content-container {
  position: relative;
  width: 100%;
  /* max-width: 95%; */
  height: 100%;
  margin: 0 auto;
}
.miraj-warehouse-title-page-section h1{
  margin: 0;
}
.miraj-warehouse-title-page-section {
  padding-bottom: 30px;
}
.single-form-element-container iframe {
  width: 100% !important;
}

/*===============================
# Header, footer
=================================*/
.warehouse-main-page-wrapper{
  min-height: calc(100vh - 30px);
}
.miraj-warehouse-footer  {
  width: 100%;
  /* height: 300px;             */
  position: relative;
  /* min-height: 50px; */
  box-sizing: border-box;
}
.content-of-miraj-warehouse-footer{
  /* position: relative;
  width: 100%;
  max-width: 95%;
  height: 100%;
  margin: 0 auto;
  padding: 35px 0; */
}
.logo-box-of-info-box-of-miraj-warehouse-footer img {
  max-width: 100px;
}
.info-box-of-miraj-warehouse-footer{
  position: relative;
  width: 100%;
  height: 100%;
}
.content-of-info-box-of-miraj-warehouse-footer {
  position: relative;
  width: 100%;
  height: 100%;
}
/* .logo-box-of-info-box-of-miraj-warehouse-footer {
  padding: 50px 0 0;
} */
.content-of-miraj-warehouse-footer {
  padding: 35px 0;
}
.menu-of-miraj-warehouse-footer {
  position: relative;
  width: 100%;
  height: 100%;
}
.content-of-menu-of-miraj-warehouse-footer {
  position: relative;
  width: 100%;
  height: 100%;
}
.menu-of-miraj-warehouse-footer .content-of-miraj-warehouse-menu-header {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.copyright-text-footer {
  text-align: right;
}
/*===============================
# Card Data Displyer
=================================*/
.card-data-displayer-message-container {
  padding-top: 15px;
}
.title-box-of-card-data-displayer-container h3 {
  margin: 0;
  font-size: 25px;
}
.all-the-cards-of-card-data-displayer-container {
  padding-top: 35px;
}
.content-of-all-the-cards-of-card-data-displayer-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.single-card-data-displayer-box {
  position: relative;
  width: 100%;
  height: 600px;
  border-radius: 10px;
  overflow: hidden;
}
.content-of-single-card-data-displayer-box {
  position: relative;
  width: 100%;
  height: 100%;
}
.thumb-box-of-single-card-data-displayer-box {
  position: relative;
  width: 100%;
  height: 100%;
}
.mask-box-of-single-card-data-displayer-box {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
  background-color: #00000063;
}
.content-of-thumb-box-of-single-card-data-displayer-box {
  position: relative;
  width: 100%;
  height: 100%;
}
.thumb-img-card-data-displyer {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
.text-box-of-mask-box-of-single-card-data-displayer-box {
  position: absolute;
  bottom: 35px;
  width: 100%;
  color: white;
}
.text-box-of-mask-box-of-single-card-data-displayer-box h3{
  margin: 0;
}
.text-box-of-mask-box-of-single-card-data-displayer-box p {
  margin: 10px 0 0;
}
.content-of-text-box-of-mask-box-of-single-card-data-displayer-box {
  padding: 0 35px 0;
}
/*===============================
# Single Card
=================================*/
.single-business-card-page-container {
  /* position: fixed; */
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f2f2;
  overflow-y: scroll;
  /* background-position: center; */
    /* background-size: cover; */
    min-height: 100vh;
}
.content-of-single-business-card-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 35px;
}
.img-thumbnail-box-of-header-box-of-single-business-card-container img {
  max-width: 100%;
  background-color: #f0f0f0;
}
.content-of-img-thumbnail-box-of-header-box-of-single-business-card-container{
  position: relative;
  width: 100%;
  height: 100%;
}
.single-business-card-container{
  position: relative;
  max-width: 450px;
  min-width: 450px;
  width: 100%;
}
.content-of-single-business-card-page-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 35px 0;
  flex-direction: row;
  align-items: center;
  flex-direction: column;
}
.single-business-card-container {
  position: relative;
  max-width: 450px;
  background-color: white;
  overflow: hidden;
  border-radius: 25px;
  box-shadow: 0px 6px 30px #0000000f;
}
.header-box-of-single-business-card-container {
  background: #4f7bf7;
  inset: 0px;
  clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 7rem), 0px 100%);
  content: "";
  position: absolute;
}
.header-box-of-single-business-card-container {
  position: relative;
  height: 300px;
}
.card-profile-details-box  {
  color: white;
}
.card-profile-details-box h3 {
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 400;
  padding-top: 25px;
}
.card-profile-details-box h2{
  margin: 0;
  font-size: 2.25rem;
  font-weight: 800;
  line-height: 2.25rem;
}
.card-profile-details-box p {
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 400;
}
.content-of-card-profile-details-box {
  position: relative;
  padding: 35px;
}
.img-thumbnail-box-of-header-box-of-single-business-card-container {
  position: absolute;
  bottom: 0;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border: 5px solid white;
  background-color: rgb(241 241 241);
  overflow: hidden;
  right: 25px;
  top: 140px;
}
.headline-box-of-single-business-card-container{
  padding-top: 15px;
}
.content-of-headline-box-of-single-business-card-container {
  padding: 0 35px;
}
.content-of-icon-box-of-single-card-link-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-box-of-single-card-link-box {
  position: relative;
  width: 50px;
  height: 50px;
  background: #4f7bf7;
  border-radius: 50px;
  color: white;
}
.text-box-of-single-card-link-box h3 {
  font-size: 1.1rem;
  line-height: 1.5em;
  margin: 0px;
  font-weight: 500;
  margin: 0;
}
.text-box-of-single-card-link-box h4 {
  margin: 2px 0 0;
  font-weight: 400;
  color: #0000007a;
}
.content-of-single-card-link-box {
  padding: 15px 35px;
}
.text-box-of-single-card-link-box {
  position: relative;
  width: 100%;
  height: 100%;
}
.content-of-text-box-of-single-card-link-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.card-links-container {
  padding: 15px 0 0;
}
.single-card-link-box {
  position: relative;
  transition: all 0.35s ease-in-out;
}
.single-card-link-box:hover,
.single-card-link-box:focus {
  background-color: #0000000a;
  cursor: pointer;
}
.google-maps-link-box{
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
/* .add-to-contacts-card-button {
  position: fixed;
  z-index: 999;
  bottom: 35px;
} */
.add-to-contacts-card-button {
  position: relative;
  padding: 20px 0 0;
}
.add-to-contacts-card-button button {
  color: white;
  border: none;
  width: 100%;
  border-radius: 50px;
  padding: 0 30px;
  transition: all 0.35s ease-in-out;
}
.add-to-contacts-card-button button:hover, .add-to-contacts-card-button button:focus{
  border: none !important;
  opacity: .8;
}
/*===============================
# Card Builder
=================================*/
.head-box-of-card-builder-page-container {
  position: relative;
  border-bottom: 1px solid #00000017;
}
.head-box-of-card-builder-page-container h3{
  margin: 0 0 0 5px;
}
.content-of-head-box-of-card-builder-page-container {
  position: relative;
  padding: 15px 35px;
  display: flex;
  align-content: center;
  align-items: center;
}
.icon-box-of-single-card-link-box svg path,
.icon-box-of-single-card-link-box svg path#Path {
  fill: white;
}
.content-of-card-builder-form-container {
  position: relative;
  padding: 35px;
}
.content-of-card-builder-page-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
}
.card-builder-page-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}
.content-of-right-side-of-card-builder-page-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.left-side-of-card-builder-page-container {
  position: relative;
  width: 20%;
  height: 100%;
  overflow-y: scroll;
  border-right: 1px solid #00000017;
}
.right-side-of-card-builder-page-container {
  position: relative;
  width: 80%;
  height: 100%;
  /* background-position: center !important;
  background-size: cover !important; */
}
.actual-card-of-card-builder-page-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.card-builder-page-container .content-of-actual-card-of-card-builder-page-container{
  display: block;
}
.card-builder-page-container .single-business-card-container {
  margin: 0 auto;
}
.content-of-actual-card-of-card-builder-page-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
/*   align-items: flex-start; */
  align-items: center;
  justify-content: center;
  padding: 35px 0;
}
.card-builder-title-box h3{
  margin: 0;
}
.card-builder-title-box{
  padding-bottom: 10px;
}
.card-links-builder-container .card-builder-title-box {
  padding: 35px 0;
}
.content-of-additional-links-modal-boxes {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.link-type-additional-box {
  width: 100px;
  height: 100px;
  padding: 5px;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.35s ease-in-out;
}
.link-type-additional-box:hover, .link-type-additional-box:focus{
  background-color: #0000000d;
}
.content-of-link-type-additional-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-of-link-type-additional-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.link-type-additional-box h3 {
  font-weight: 400;
  margin: 5px 0 0;
  font-size: 15px;
}
.content-of-single-builder-link-box {
  padding: 35px;
}
.single-builder-link-box {
  border: 1px solid #00000017;
  background-color: white;
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}
.content-of-drag-icon-box-of-single-builder-link-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.drag-icon-box-of-single-builder-link-box {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: grab;
}
.create-or-update-container-of-card-builder-page-container {
  position: relative;
  padding-top: 20px;
}
.request-response-message-container {
  padding: 15px 0 0;
}
.loading-box-of-single-builder-link-box.active{
  opacity: 1;
  visibility: visible;
}
.loading-box-of-single-builder-link-box {
  position: absolute;
  background-color: #ffffffcc;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transition: all 0.35s ease-in-out;
}
.content-of-loading-box-of-single-builder-link-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-of-title-box-of-single-card-link-box {
  padding: 3px;
}
.title-box-of-single-card-link-box {
  margin: 0 35px;
  text-align: center;
  color: white;
  border-radius: 10px;
  margin-top: 10px;
}
/*===============================
# Card Template 02
=================================*/
.avatar-box-of-card-template-02-header-box{
  position: relative;
  width: 100%;
  height: 100%;
}
.content-of-avatar-box-of-card-template-02-header-box {
  position: relative;
  width: 100%;
  height: 100%;
}
.content-of-card-template-02-header-box {
  position: relative;
  width: 100%;
  height: 100%;
}
.card-template-02-header-box {
  clip-path: circle(100% at 50% 0%);
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  height: 450px;
}
.thumb-of-avatar-box-of-card-template-02-header-box {
  position: relative;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}
.card-template-02-user-info-box {
  position: relative;
  width: 70%;
  margin: -86px auto 0;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px, rgb(0 0 0 / 6%) 0px 1px 2px;
}
.card-template-02-user-info-box h3{
  margin: 0;
  font-size: 1.75rem;
  font-weight: 800;
  line-height: 2.25rem;
}
.card-template-02-user-info-box h4 {
  margin: 2px 0 0;
  font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5rem;
    white-space: pre-wrap;
}
.card-template-02-user-info-box p {
  margin: 0;
  font-size: 1.1rem;
    /* font-style: italic; */
    font-weight: 300;
    line-height: 1.5rem;
}
.card-template-02-user-info-box {
  text-align: center;
}
.content-of-card-template-02-user-info-box {
  position: relative;
  padding: 25px;
}
/* .single-business-card-container.card-template-02 .content-of-card-links-container {
  background-color: #0000000a;
} */
.content-of-all-card-templates-boxes {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.single-card-template-box.active {
  background-color: #f05f2a;
  color: white;
  border: 2px solid #f05f2a;
}
.single-card-template-box.active:hover, .single-card-template-box.active:focus{
  background-color: #1701B7;
  border: 2px solid #1701B7;
  color: white;
}
.single-card-template-box h3 {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.25s ease-in-out;
}
.single-card-template-box {
  position: relative;
  border: 2px solid #0000002e;
  border-radius: 5px;
  padding: 15px;
  margin-right: 5px;
  color: #00000070;
  transition: all 0.25s ease-in-out;
}
.single-card-template-box:hover, .single-card-template-box:focus {
  border: 2px solid #0000008c;
  border: 2px solid #0000004a;
  background-color: #0000001a;
  color: #00000091;
  cursor: pointer;
}
.all-card-templates-boxes {
  position: relative;
  padding: 5px 0 0;
}
.select-template-container {
  padding-top: 40px;
}
.single-business-card-container a {
  color: inherit;
  text-decoration: none;
}
img.single-card-company-logo-img {
  max-width: 150px;
  padding-bottom: 5px;
}
.single-card-link-box iframe {
  border: none;
  width: 100%;
  height: 210px;
  border-radius: 10px;
}
.emdebed-video-of-single-card-link-box h3 {
  margin: 0 0 0;
  font-size: 1.1rem;
  line-height: 1.5em;
  font-weight: 600;
  margin: 0;
}
.iframe-of-emdebed-video-of-single-card-link-box {
  padding-top: 10px;
}
.all-the-links-of-card-links-builder-container ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

/*===============================
# Folders
=================================*/
.folders-list-container .MuiAccordion-root {
  position: relative;
  background-color: white;
  border: 1px solid #00000017;
  border-radius: 5px;
  box-shadow: none !important;
  margin-bottom: 10px;
}
.folders-list-container .MuiAccordion-root:before{
  background-color: transparent;
}
.single-folder-chip-box {
  position: relative;
  background-color: white;
  border: 1px solid #00000017;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.folder-chips-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.content-of-info-boxes-of-single-folder-chip-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -10px;
}
.single-info-box-of-folder-chip h3{
  margin: 3px 0 0;
}
.single-info-box-of-folder-chip h4 {
  margin: 0;
    font-weight: 400;
    font-size: 15px;
}
.content-of-single-folder-chip-box {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 35px;
  box-sizing: border-box;
  overflow-x: scroll;
}
.content-of-loading-chip-details-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-chip-details-box.active{
  visibility: visible;
  opacity: 1;
}
.loading-chip-details-box {
  position: absolute;
  background-color: #ffffffbd;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  transition: all 0.35s ease-in-out;
}
.content-of-load-more-item-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.load-more-item-container {
  position: relative;
  padding: 10px 0;
}
.load-more-item-container button {
  border-radius: 50px;
  box-shadow: none;
}
.content-of-folder-header-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.edit-folder-name-icon-button svg {
  width: 18px;
  height: 18px;
}
.edit-folder-name-icon-button {
  margin: -4px 0 0 2px;
}
.edit-folder-input-box {
  position: absolute;
  z-index: 3;
  background-color: white;
  width: 300px;
  top: -4px;
  left: 40px;
}
.folders-list-container .Mui-focusVisible{
  background-color: transparent !important;
}
/*===============================
# Chip View Page
=================================*/
.preloader-of-view-single-chip-page-container.active{
  opacity: 1;
  visibility: visible;
}
.preloader-of-view-single-chip-page-container {
  position: fixed;
  z-index: 9999;
  background-color: white;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.35s ease-in-out;
}
.content-of-preloader-of-view-single-chip-page-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
/*===============================
# Slider
=================================*/
.single-slider-in-digital-business-card-box .each-slide-effect > div {
  height: 210px;
}
.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 500px;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}
.slider-editor-container {
  position: relative;
  width: 50%;
  height: 100%;
}
.single-slider-container{
  position: relative;
  width: 100%;
  height: 100%;
}
.single-slider-container button.nav.default-nav {
  margin-left: 15px;
  margin-right: 15px;
}
.single-slider-container button.nav.default-nav {
  margin-left: 15px;
  margin-right: 15px;
  background: #ffffff00;
  fill: #ffffffa1;
  transition: all 0.35s ease-in-out;
}
.single-slider-container button.nav.default-nav:hover,
.single-slider-container button.nav.default-nav:focus {
  background: #ffffff30;
  fill: #ffffff;
}
.mask-of-single-slider-container {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: rgb(0,0,0);
  background: linear-gradient(356deg, rgb(0 0 0 / 63%) 0%, rgba(255,255,255,0) 100%);
  color: white;
}
.mask-of-single-slider-container h3 {
  margin: 0;
  font-size: 24px;
}
.content-of-mask-of-single-slider-container {
  position: absolute;
  bottom: 35px;
  padding: 0 35px;
}
.slider-editor-container .single-slider-container {
  margin-bottom: 35px;
}
.content-of-slider-options-container {
  padding: 35px;
}
.slider-options-container {
  border: 1px solid #00000017;
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 100%;
}
.slides-of-slider-options-container ul {
  margin: 0;
  padding: 0;
}
.slides-of-slider-options-container li{
  list-style: none;
}
.single-editable-slide-box{
  border: 1px solid #00000017;
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  background-color: white;
}
.content-of-single-editable-slide-box{
  padding: 35px;
}
.slides-of-slider-options-container {
  padding: 35px 0 0;
}
.slides-editor-title {
  margin: 0 0 10px;
}
.single-editable-slide-box .form-element-image-uploader-container {
  border: none;
}
.single-editable-slide-box .content-of-form-element-image-uploader-container {
  padding: 0;
}
.buttons-box-of-single-editable-slide-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.action-buttons-of-slider-editor-container{
  padding-top: 30px;
}
/* .content-of-single-slider-in-digital-business-card-box{
  padding: 0 35px;
} */
.single-slider-in-digital-business-card-box .mask-of-single-slider-container {
  display: none;
}
.title-box-of-slider-card-container h3 {
  margin: 0;
  text-align: center;
  color: white;
}
.title-box-of-slider-card-container {
  padding: 15px;
}
.slider-card-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
/*===============================
# Add new Link
=================================*/
.add-new-link-box {
  border: 1px solid #00000017;
  background-color: white;
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 100%;
  color: #4b4b4b;
  transition: all 0.35s ease-in-out;
}
.add-new-link-box:hover, .add-new-link-box:focus{
  cursor: pointer;
  background-color: #f6f6f6;
}
.add-new-link-box h3{
  margin: 0 0 0 5px;
}
.content-of-add-new-link-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35px;
}
/*===============================
# Image Link
=================================*/
.content-of-single-card-thumbnail-box {
  position: relative;
  width: 100%;
  height: 100%;
}
.single-card-thumbnail-box {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.img-box-of-single-card-thumbnail-box {
  position: relative;
  width: 100%;
  height: 250px;
  background-size: cover;
  background-position: center;
}
.content-of-editor-for-single-card-link-box {
  padding: 15px 35px;
}
.editor-for-single-card-link-box img {
  max-width: 100%;
}
/*===============================
# Two Factor Auth
=================================*/
.icon-box-of-two-factor-auth-head-box{
  position: relative;
  background-color: #f05f2a;
  width: 50px;
  height: 50px;
  color: white;
  border-radius: 50px;
}
.content-of-icon-box-of-two-factor-auth-head-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-of-two-factor-auth-head-box {
  position: relative;
  width: 100%;
  height: 100%;
  /* display: flex; */
}
.two-factor-auth-head-box h3{
  margin: 0;
}
.two-factor-auth-head-box p{
  margin: 10px 0 0;
}
.text-box-of-two-factor-auth-head-box {
  margin-left: 10px;
}
.set-up-two-factor-auth-page .content-of-text-box-of-two-factor-auth-head-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.set-up-two-factor-auth-page .text-box-of-two-factor-auth-head-box {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
}
p.two-factor-auth-description {
  margin: 0;
  line-height: 1.4;
}
.two-factor-auth-qr-code-container img {
  max-width: 150px;
}
.two-factor-auth-qr-code-container {
  border: 1px solid #00000017;
  border-radius: 5px;
  margin: 30px 0 0;
}
.content-of-two-factor-auth-qr-code-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35px;
}
/*===============================
# Block Account Page
=================================*/
.info-box-of-block-account-page-container.error {
  background-color: #dc000021;
  color: #dc0000;
}
.icon-box-of-info-box-of-block-account-page-container{
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
.info-box-of-block-account-page-container.error .icon-box-of-info-box-of-block-account-page-container{
  background-color: #dc000029;
}
.info-box-of-block-account-page-container.success {
  background-color: #5f8d4e24;
  color: #5F8D4E;
}
.info-box-of-block-account-page-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  border-radius: 15px;
  text-align: center;
}
.text-box-of-info-box-of-block-account-page-container h2{
  margin: 0;
}
.text-box-of-info-box-of-block-account-page-container p {
  margin: 10px 0 0;
  line-height: 1.5;
}
.content-of-icon-box-of-info-box-of-block-account-page-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-of-info-box-of-block-account-page-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 35px;
}
.text-box-of-info-box-of-block-account-page-container {
  padding: 10px 0 0;
}
.block-account-page-container {
  position: relative;
  width: 100%;
  height: 100vh;
  /* background-color: red; */
}
.content-of-block-account-page-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-box-of-block-account-page-container.success .icon-box-of-info-box-of-block-account-page-container {
  background-color: #5f8d4e24;
}
.block-account-page-container.error{
  background-color: red;
}
.block-account-page-container.error .info-box-of-block-account-page-container{
  background-color: transparent;
  color: white;
}
/*===============================
# Two Factor Auth
=================================*/
.two-factor-auth-code-field-container {
  background-color: white;
  border: 1px solid #00000017;
  border-radius: 5px;
}
.content-of-two-factor-auth-code-field-container {
  padding: 35px;
}
.two-factor-auth-code-field-container .content-of-two-factor-auth-head-box {
  display: flex;
}
.reset-password-form .icon-box-of-two-factor-auth-head-box {
  display: none;
}
/*===============================
# Not Found Page
=================================*/
.not-found-page-message-container {
  position: relative;
  width: 100%;
  height: 100vh;
}
.content-of-not-found-page-message-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.not-found-page-icon-container img {
  width: 150px;
}
.text-box-of-not-found-page-icon-container{
  text-align: center;
  padding-top: 15px;
}
.text-box-of-not-found-page-icon-container h1 {
  font-size: 32px;
  margin: 0;
}
.text-box-of-not-found-page-icon-container p {
  font-size: 18px;
  margin: 10px 0 0;
  line-height: 1.5;
}
/*===============================
# Crop Dialog
=================================*/
.buttons-box-of-image-cropper-dilog-box {
  position: fixed;
  width: 100%;
  height: 75px;
  z-index: 999;
  bottom: 0;
  background-color: white;
}
.content-of-buttons-box-of-image-cropper-dilog-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-cropper-dilog-box {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: white;
}
.content-of-image-cropper-dilog-box {
  position: relative;
  width: 100%;
  height: 100%;
}
.image-uploader-cropper-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.image-cropper-dilog-box .reactEasyCrop_Container {
  width: 500px !important;
  margin: 0 auto;
  /* height: 100%; */
}
/*===============================
# Miraj SOP
=================================*/
.sections-list-container-of-miraj-sop-admin-page-container {
  padding-top: 15px;
}
button#section_modal_form_elem_submit {
  position: absolute;
  top: -1000px;
}
.section-form-container-in-modal {
  padding-top: 15px;
}
.content-of-buttons-box-of-mask-text-of-single-section-box {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 35px 0 35px;
  transition: all 0.45s ease-in-out;
}
.single-section-box:hover .content-of-buttons-box-of-mask-text-of-single-section-box {
  padding: 35px 35px 35px;
}
.buttons-box-of-mask-text-of-single-section-box button{
  margin-right: 10px !important;
  transition: all 0.45s ease-in-out;
}
.single-section-box:hover .buttons-box-of-mask-text-of-single-section-box button{
  margin-right: 0 !important;
}
.buttons-box-of-mask-text-of-single-section-box {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  opacity: 0;
  transition: all 0.45s ease-in-out;
}
.single-section-box:hover .buttons-box-of-mask-text-of-single-section-box{
  opacity: 1;
}
.buttons-box-of-mask-text-of-single-section-box button{
  color: black;
  margin-left: 5px !important;
  background-color: white;
}
.buttons-box-of-mask-text-of-single-section-box button:hover,
.buttons-box-of-mask-text-of-single-section-box button:focus{
  background-color: white;
  opacity: .8;
}
.sop-page-section-container{
  margin-top: 100px;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: white;
  transition: all 0.45s ease-in-out;
}
.sop-page-section-container.active {
  margin-top: 50px;
  opacity: 1;
  visibility: visible;
}
.title-box-of-single-section-box{
  position: relative;
  z-index: 10;
  cursor: pointer;
}
.sop-admin-section-categories-list-container{
  padding-top: 15px;
}
#categoreis_form_modal_elem_submit{
  position: absolute;
  top: -10000px;
}
.post-editor-modal-container-wrapper{
  transition: all 0.45s ease-in-out;
}
.post-editor-modal-container-wrapper.active{
  visibility: visible;
  opacity: 1;
}
.post-editor-modal-container-wrapper.active .mask-of-post-editor-sop-modal {
  opacity: 1;
  visibility: visible;
}
.post-editor-modal-container-wrapper.active .post-editor-sop-modal{
  visibility: visible;
  opacity: 1;
  bottom: 0;
}
.mask-of-post-editor-sop-modal {
  position: absolute;
  background-color: #000000b3;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  transition: all 0.45s ease-in-out;
}
.post-editor-modal-container-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.45s ease-in-out;
}
.post-editor-sop-modal {
  background-color: white;
  width: 50%;
  height: 100%;
  z-index: 9;
  position: relative;
  margin: 80px auto;
  border-radius: 50px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  overflow: hidden;
  bottom: -1000px;
  opacity: 0;
  transition: all 0.45s ease-in-out;
}
.content-of-post-editor-sop-modal {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 35px;
  overflow-y: scroll;
}
.text-of-head-box-of-post-modal-sop-container-content h3{
  margin: 0;
}
.content-of-head-box-of-post-modal-sop-container-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.post-form-block-editor-container {
  border: 1px solid #0000002b;
  padding: 25px 0;
  margin-top: 25px;
  border-radius: 5px;
  min-height: 500px;
}
.form-box-of-post-modal-sop-container-content {
  padding-top: 25px;
}
.buttom-of-post-modal-sop-container-content {
  padding-top: 25px;
}
.content-of-buttom-of-post-modal-sop-container-content {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.load-more-items-button-container {
  margin-bottom: 10px;
}
.single-post-detail-item-box h3{
  margin: 5px 0 0;
  font-weight: 400;
}
.single-post-detail-item-box h4{
  margin: 0;
  font-weight: 500;
}
.single-post-detail-item-box {
  border-bottom: 1px solid rgb(217, 217, 217);
  padding: 15px 0;
}
.single-post-detail-item-box:last-child{
  border-bottom: none;
}
.side-menu-for-sop-items-container {
  margin-bottom: 15px;
}
.content-of-side-menu-for-sop-items-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}
.listed-items-container-miraj-sop.selectable .right-icon-box-of-single-category-box,
.listed-items-container-miraj-sop.draggable .right-icon-box-of-single-category-box {
  pointer-events: none;
  cursor: default;
  opacity: .5;
}
.listed-items-container-miraj-sop.selectable .single-category-box:hover .icon-box-of-single-category-box,
.listed-items-container-miraj-sop.draggable .single-category-box:hover .icon-box-of-single-category-box {
  margin-left: 0;
}
.listed-items-container-miraj-sop.draggable .single-category-box{
  pointer-events: none;
}
.listed-items-container-miraj-sop.selectable .single-category-box{
  cursor: pointer;
  pointer-events: none;
}
.listed-items-container-miraj-sop ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.disabled-content{
  pointer-events: none;
  opacity: .5;
}
.selected-items-menu-container {
  position: relative;
  background: #f05f2a1c;
  border-radius: 10px;
  margin-bottom: 10px;
}
.content-of-selected-items-menu-container {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-content: center;
  align-items: center;
  padding: 15px 35px;
  gap: 10px;
}
.text-box-of-selected-items-menu-container h3{
  margin: 0;
  color: #f05f2a;
  font-weight: 500;
}
.text-box-of-selected-items-menu-container {
  width: 30%;
}
.buttons-of-selected-items-menu-container {
  width: 70%;
}
.content-of-buttons-of-selected-items-menu-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}
.wrapper-of-single-category-box.selected .single-category-box {
  background: #f05f2a1c;
  color: #f05f2a;
}
.wrapper-of-single-category-box.selected svg{
  color: #f05f2a !important;
}
.single-displayed-item-for-move-modal{
  position: relative;
    background-color: #f0f0f0;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    color: #2e2e2e;
    transition: all 0.45s ease-in-out;
    margin-bottom: 10px;
}
.title-box-of-single-displayed-item-for-move-modal h3{
  font-weight: 500;
    transition: all 0.45s ease-in-out;
}
.content-of-single-displayed-item-for-move-modal {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px 35px;
  display: flex;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
}
/*===============================
# ReCapctha
=================================*/
.single-form-element-container iframe {
  width: 100% !important;
}
.google-recaptcha-box {
  transform: scale(1.072);
  transform-origin: 0 0;
  margin-top: -15px;
  padding-top: 10px;
}
/*===============================
# Responsive
=================================*/
@media screen and (max-width: 1024px){
    .show-mobile-page-post-content-container{
      display: block !important;
    }
    .post-sidebar-section-of-the-post{
      display: none !important;
    }
    .post-html-content-section{
      width: 100% !important;
    }
    .content-of-post-html-content-section{
      padding: 0 !important;
    }
}
@media screen and (max-width: 786px){
  .single-card-statistics-page-container .MuiGrid-item {
    width: 100% !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .post-additional-info-boxes-container {
    max-width: 100%;
    overflow-x: scroll;
    -webkit-mask-image: linear-gradient(90deg, #0000 0, #000 10%, #000 90%, #0000);
    mask-image: linear-gradient(91deg, #0000 0, #000 0%, #000 70%, #0000);
    /* margin-bottom: -10px; */
  }
  .content-of-title-of-single-section-details-box {
    gap: 5px;
    flex-wrap: wrap;
  }
  .title-of-single-section-details-box button{
    display: none;
  }
  .additional-post-info-title-box {
    display: inline-block;
  }
  .post-additional-info-boxes-container::-webkit-scrollbar-thumb {
    background: transparent;
    height: 0;
  }
  .additional-post-info-title-box:last-child{
    padding-right: 50px;
  }
  .breadcrumbs-of-single-section-details-box::-webkit-scrollbar-thumb{
    background: transparent;
  }
  .breadcrumbs-of-single-section-details-box::-webkit-scrollbar{
    height: 0;
  }
  .breadcrumbs-of-single-section-details-box{
    max-width: 100%;
    overflow-x: scroll;
    -webkit-mask-image: linear-gradient(90deg, #0000 0, #000 10%, #000 90%, #0000);
    mask-image: linear-gradient(91deg, #0000 0, #000 0%, #000 70%, #0000);
  }
  .content-of-additional-post-info-title-box{
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    display: block;
  }
  .breadcrumbs-container a{
    min-width: 30%;
  }
  .icon-box-of-single-category-box{
    width: 15% !important;
    margin-top: 5px !important;
  }
  .content-of-breadcrumbs-container{
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    display: block;
  }
  .content-of-breadcrumbs-container::-webkit-scrollbar-thumb{
    background: transparent;
  }
  .content-of-breadcrumbs-container::-webkit-scrollbar{
    height: 0;
  }
  .single-breadcrumb-item-box {
    display: inline-block;
  }
  .single-breadcrumb-item-box:last-child{
    padding-right: 50px;
  }
  .single-post-page-content .breadcrumbs-of-single-section-details-box{
    padding-top: 0 !important;
  }
  .mobile-post-bottom-buttons{
    display: block !important;
  }
  .sign-in-page-container .single-form-element-container {
    text-align: center !important;
  }
  .content-of-sign-in-page-container{
    padding: 0 20px !important;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 500px){
  .single-business-card-container {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
  }
  .content-of-single-business-card-page-container {
    padding: 35px 15px;
    box-sizing: border-box;
}
  .card-template-02-user-info-box{
    width: 80%;
  }
  .text-box-of-single-card-link-box {
    padding-left: 10px;
  }
  .header-box-of-single-business-card-container {
    height: 430px !important;
  }
  .img-thumbnail-box-of-header-box-of-single-business-card-container{
    top: 280px !important;
  }
}